const appContent = {
  header: {
    alertBanner: 'White Rhino presents',
    tagline: 'The AI-Powered Antidote to Resolution Monotomy.',
    links: [
        {
          title: 'Home',
          path: '/',
        },
        {
          title: 'About This App',
          path: '/about',
        },
        {
          title: 'FAQs',
          path: '/faqs',
        }
      ]
  },
  footer: {
    copyright: '© 2024 White Rhino. All rights reserved.',
    privacyPolicyLink: {
      title: 'Privacy Policy',
      path: '#',
    }
  },
  pages: {
    home: {
      hero: {
        headline: 'If one of your New Year’s resolutions is to experiment more with AI, we have just the x. ',
        text: 'Madlib-O-Matic is driven entirely by AI, start to finish. It’s the AI-powered antidote to resolution monotony, and even includes AI-generated images inspired by your wacky word choice.',
        buttonLink: 'mad-lib-form',
        buttonText: 'Take it for a spin!',
      },
      form: {
        headline: 'Fill in the fields to generate',
      },
      results: {
        headline: 'Compare AI Image Generators',
        placeholder: {
          text: 'Why is it taking so long to load?',
          button: {
            title: 'View FAQs',
            path: '/faqs'
          }
        }
      }
    },
    faqs: {
      hero: {
        headline: 'Frequently Asked Questions',
      },
      accordions: [
        {
          title: 'Question 1',
          content: 'Answer goes here.'
        },
        {
          title: 'Question 2',
          content: 'Answer goes here.'
        }
      ],
      cta: {
        headline: 'Generate a New Resolution',
        button: {
          title: 'Generate my mad-lib!',
          path: '/'
        }
      }
    }    
  },
  components: {
    madLibForm: {
      title: 'Fill in the fields to generate',
      buttonText: 'Generate my mad-lib!',
    }    
  }
};

export default appContent;
