import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import MadLibForm from './MadLibForm';
import MadLibResult from './MadLibResult';
import { useAppContext } from '../context/AppContext';
import '../styles/madLibContainer.scss';

const MadLibContainer = () => {
  const [madLibHtml, setMadLibHtml] = useState('');
  const [completedMadLib, setCompletedMadLib] = useState('');
  const [madLibImages, setMadLibImages] = useState('');
  const [loadedMadLib, setLoadedMadLib] = useState('');
  const location = useLocation();
  const { replaceWords, setIsMadLibCompleted } = useAppContext();

  const WRHerokuEndpoint = process.env.REACT_APP_WR_HEROKU_ENDPOINT;
  const WRDBEndpoint = process.env.REACT_APP_WR_DB_ENDPOINT;

  const handleFetchComplete = useCallback((madLib) => {
    setMadLibHtml(madLib); //get madLibHtml so we can pass it back down to MadLibForm
  }, []);

  const handleCompleteMadLib = async (completedMadLib) => {
    setCompletedMadLib(completedMadLib);
    setIsMadLibCompleted(true);
    fetchMadLib();
  };

  const fetchMadLib = async () => {
    const prompt = `
    Create a short witty Valentines-themed mad lib following these instructions precisely:

    1. **Title**: Write a witty and creative title. Do not include blanks in the title. Wrap the title in <h2> tags. 

    2. **Sentence Construction**: Craft exactly 2 or 3 very concise sentences for the mad lib. Keep the content brief.

    3. **Blanks**: Include up to 5 blanks in total within the sentences. Do not exceed this number. Do not ask for pronouns, adverbs, or names. Include a completely unrelated random word (along with the specific part of speech it is) for each blank, for example: [noun|dog], [verb|sneeze], [adjective|warm], [superlative adjective|spiciest], etc.

    4. **Common Mistakes to Avoid**:
       - Do not insert blanks into the title.
       - Ensure there are no more than 5 blanks in the entire mad lib.

    5. **HTML Format**: Present the mad lib in correctly formatted HTML. Strictly follow this structure: <h2>Your Title Here</h2><p>Sentence 1. Sentence 2.</p> 

    6. **Final Review and Submission**: Triple-check your work to ensure it meets all the criteria mentioned above exactly. Only submit the mad lib HTML.

    Examples:
    - <h2>The Christmas Cookie Catastrophe</h2><p>Every Christmas, we try to bake [plural noun|dogs], but they always turn out [adjective|slippery]. Last year, they started to [verb|sneeze]!</p>

    - <h2>The Snowman Shenanigans</h2><p>When the [noun|desk] fell, all the kids rushed out to [verb|decorate] the most [adjective|sleepy] snowman, leading to a wild [noun|pickle] fight!</p>

    - <h2>The Holiday Mishap</h2><p>The lights were [adjective|wrinkled], the [noun|keys] were [verb|chatting], but then Santa got stuck in the [noun|printer]!</p>

    - <h2>Reindeer Games Gone Wild</h2><p>The [adjective|clumsy] reindeer decided to [verb|eat] the [noun|spoon], causing a [adjective|sparkly] commotion at the North Pole.</p>

    - <h2>The Elf's Secret Ingredient</h2><p>Curiosity was [adjective|cold] when the elf added a [animal|octupus] to the cookies, making them [verb|crawl] with joy!</p>

    - <h2>The Mistletoe Mishap</h2><p>Under the mistletoe, two [plural noun|trees] decided to [verb|laugh] instead of kiss, leading to [adjective|colorful] laughter.</p>

    By adhering strictly to these guidelines, ensure your mad lib is consistent and correctly formatted. Please pay close attention to the specific instructions about the title and the number of blanks.
    `; 
    // Ensure your environment variables for openAIEngine and openAIapiKey are set correctly
    const openAIEngine = process.env.REACT_APP_OPENAI_ENGINE;
    const openAIapiKey = process.env.REACT_APP_OPENAI_API_KEY;

    try {
      const response = await fetch('https://api.openai.com/v1/engines/' + openAIEngine + '/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${openAIapiKey}`
        },
        body: JSON.stringify({
          prompt: prompt,
          max_tokens: 400
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const trimmedMadLib = data.choices[0].text.trim();
      setMadLibHtml(trimmedMadLib);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const endpointParam = urlParams.get('id');
    setLoadedMadLib(endpointParam ? 'true' : 'false');
    const loadMadLib = async () => {
      try {
        const response = await fetch(`${WRHerokuEndpoint}/${WRDBEndpoint}/get_madlib.php?id=` + endpointParam);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (data.error) {
          console.error(data.error);
        } else {
          let resultString = replaceWords(data.MadLib, data.UserSubmission);
          setMadLibImages([data.ImageURL_Dalle, data.ImageURL_Stability, data.ImageURL_MidJourney]);
          setMadLibHtml(resultString);
          setCompletedMadLib(resultString); 
          setIsMadLibCompleted(true);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };

    if (endpointParam) {
      loadMadLib();
      fetchMadLib(); //generate new form if loading from a url param
    } else {
      fetchMadLib();
    }
  }, [location.search]); // Re-run effect when the url changes

  return (
    <div className="mad-lib-container">
      {completedMadLib && (
        <div>
          <MadLibResult loadedMadLib={loadedMadLib} completedMadLib={completedMadLib} madLibImages={madLibImages} />
        </div>
      )}
        <div>
          <MadLibForm generatedMadLib={madLibHtml} onCompleteMadLib={handleCompleteMadLib} />
        </div>

    </div>
  );
};

export default MadLibContainer;
