// Dalle.js

import React, { useEffect, useState, useRef } from 'react';
import { useAppContext } from '../context/AppContext';
import ImgPlaceholder from './ImgPlaceholder'; // with import

const Dalle = ({ imagePrompt, loadedImage }) => {
  const [loading, setLoading] = useState(true);
  const [imageURL, setImageURL] = useState('');
  const { updateDataObject, dataObj, convertImageUrlToBase64 } = useAppContext();

  const openAIEngine = process.env.REACT_APP_OPENAI_ENGINE;
  const openAIapiKey = process.env.REACT_APP_OPENAI_API_KEY;
  const herokuEndpoint = process.env.REACT_APP_WR_HEROKU_ENDPOINT;

  useEffect(() => {
    if(loadedImage) {
      setImageURL(loadedImage);
      setLoading(false);
    } else {
        generateDalleImage();      
    }
  }, [imagePrompt]);

  const generateDalleImage = async () => {
    console.log('generateDalleImage');
    try {
      setLoading(true);

      // Dall-e image generation logic
      var dalle_parameters = ' Pixar-style, Pixar style. Do not include any words, text, letters, or dates.';
      var dalle_prompt = imagePrompt + dalle_parameters;
      
      const response = await fetch('https://api.openai.com/v1/images/generations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${openAIapiKey}`
        },
        body: JSON.stringify({
          model: 'dall-e-3',
          prompt: dalle_prompt,
          n: 1,
          size: '1024x1024',
        })
      });

      const data = await response.json();
      const imageURLForConversion = 'https://hidden-woodland-30546-63b4d94254dd.herokuapp.com/' + data.data[0].url;
      const imageURL = data.data[0].url;
      convertImageUrlToBase64(imageURLForConversion).then(convertedImage => {
        updateDataObject({
          MadLibID: dataObj.MadLibID,
          ImageURL_Dalle: convertedImage
        });
        setImageURL(convertedImage);
        setLoading(false);
      });
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  return (
    <div>
      <h4>Dall-e</h4>
      {loading ? (
        <ImgPlaceholder />
      ) : (
        <img src={imageURL} alt="Image generated by Dall-e" />
      )}
    </div>
  );
};

export default Dalle;
