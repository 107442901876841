import React, { useState } from 'react';
import '../styles/Accordions.scss';

const Accordions = ({ accordionContent }) => {
  const [openIndex, setOpenIndex] = useState(-1);

  const toggleAccordion = (index) => {
    const newIndex = openIndex === index ? -1 : index;
    setOpenIndex(newIndex);
  };

  return (
    <div className="container accordions">
      {accordionContent.map((accordion, index) => (
        <div key={index} className="accordion-item">
          <button
            onClick={() => toggleAccordion(index)}
            className="accordion-header"
            aria-expanded={openIndex === index ? "true" : "false"}
            aria-controls={`accordion-content-${index}`}
            id={`accordion-header-${index}`}
          >
            {accordion.title}
          </button>
          <div
            id={`accordion-content-${index}`}
            className={`accordion-content ${openIndex === index ? 'open' : ''}`}
            aria-labelledby={`accordion-header-${index}`}
          >
            <div className="accordion-inner">
              {accordion.content}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordions;
