// MidJourney.js

import React, { useEffect, useState, useRef } from 'react';
import { useAppContext } from '../context/AppContext';
import ImgPlaceholder from './ImgPlaceholder'; // with import
import appContent from '../data/contentData';
import '../styles/ImgMidJourney.scss';

const MidJourney = ({ imagePrompt, loadedImage }) => {
  const [showPlaceholderCallout, setShowPlaceholderCallout] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPlaceholderCallout(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div>
  		<h4>MidJourney</h4>
      <div className="mid-journey-preloader">
        <div className={`placeholder-callout ${showPlaceholderCallout ? 'fade-in' : ''}`}>
          {appContent.pages.home.results.placeholder.text}
          <a href={appContent.pages.home.results.placeholder.button.path}>{appContent.pages.home.results.placeholder.button.title}</a>
        </div>
        <ImgPlaceholder />
      </div>
    </div>
  );
};

export default MidJourney;
